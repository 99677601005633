import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { HowItWorksArtQuery } from '../../../../../graphql-types';
import logo from '../../../../images/nuborrow_logomark.svg';
import { MotionBasicCard } from '../../../basic-card';
import { FeatureSectionItem } from '../../../feature-section/model';
import ParallaxBackground from '../../../parallax-background';
import * as style from './how-it-works.module.scss';

export const howItWorksArt = () => {
  const [containerRef, inView] = useInView({ threshold: 0.15 });
  const bgImage = useStaticQuery<HowItWorksArtQuery>(dataQuery);

  const data: { rate: string; type: string }[] = [
    {
      rate: 'Rates Start at Prime',
      type: 'Home Equity Lines up to 80% LTV'
    }
  ];

  const variants = {
    visible: {
      opacity: 1,
      transition: { duration: 0.1, staggerChildren: 0.2 }
    },
    invisible: {
      opacity: 0.98
    }
  };

  const childVariants = {
    visible: {
      opacity: 1,
      translateY: '0%',
      transition: { duration: 0.4 }
    },
    invisible: {
      opacity: 1,
      translateY: '50%'
    }
  };

  let animation = inView ? 'visible' : 'invisible';

  return (
    <div
      className={style.wrapper + ' d-flex flex-column align-items-center justify-content-center'}
    >
      <ParallaxBackground
        className={style.heroImage}
        image={bgImage.file as unknown as ImageDataLike}
      />
      <motion.div
        ref={containerRef}
        className={style.cards}
        variants={variants}
        animate={animation}
      >
        {data.map((item) => (
          <MotionBasicCard
            key={`${item.rate} ${item.type}`}
            className={style.card + ' d-flex'}
            variants={childVariants}
          >
            <div className={style.logo}>
              <img src={logo} alt="Nuborrow" />
            </div>
            <div className={style.details + ' d-flex flex-column justify-content-center'}>
              <h3>{item.rate}</h3>
              <h5>{item.type}</h5>
            </div>
          </MotionBasicCard>
        ))}
      </motion.div>
    </div>
  );
};

export const getHowItWorksItem = (): FeatureSectionItem => {
  return {
    align: 'right',
    title: 'Turn your dream home into reality.',
    subtitle: 'Stop dreaming of renovations',
    description: (
      <>
        You do the planning, we'll do the shopping. Simply use the equity in your home to access the
        funds you want.
        <br />
        <br />
        Once we've found the maximum credit line or loan amount, we'll get everything set up for you
        to start renovating.
      </>
    ),
    actions: [{ label: `See how it works`, href: 'https://apply.nuborrow.com', isPrimary: true }],
    art: howItWorksArt()
  };
};

const dataQuery = graphql`
  query HowItWorksArt {
    file(relativePath: { glob: "photos/renovation-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, aspectRatio: 0.66666667, width: 1000)
      }
      relativePath
    }
  }
`;
