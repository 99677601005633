import React from 'react';
import FeatureSection from '../../../feature-section';
import { FeatureSectionProps } from '../../../feature-section/model';
import { getHowItWorksItem } from '../../shared/section-items/how-it-works';

export default function ReadyToRenovate() {
  const data: FeatureSectionProps = {
    title: 'Ready to renovate?',
    fullLengthTitle: true,
    items: [getHowItWorksItem()]
  };

  return <FeatureSection {...data} />;
}
